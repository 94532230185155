.settings-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    color: #fff;
  }
  
  .settings-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
  }
  
  .settings-header h1 {
    flex: 1;
    margin: 0;
    font-size: 24px;
    text-align: center;
  }
  
  .back-button {
    position: absolute;
    left: 0;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .settings-section {
    background: #1e1e1e;
    border-radius: 12px;
    padding: 20px;
  }
  
  .setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #333;
  }
  
  .setting-item:last-child {
    border-bottom: none;
  }
  
  .setting-info {
    flex: 1;
    margin-right: 20px;
  }
  
  .setting-info h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }
  
  .setting-info p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #888;
  }
  
  .settings-footer {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  
  .privacy-link {
    color: #bb86fc;
    text-decoration: none;
    font-size: 14px;
  }
  
  .privacy-link:hover {
    text-decoration: underline;
  }
  
  .store-link2 {
    color: #bb86fc;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background: rgba(187, 134, 252, 0.1);
    border-radius: 8px;
    transition: background-color 0.2s;
  }
  
  .store-link2:hover {
    background: rgba(187, 134, 252, 0.2);
    text-decoration: none;
  }
  
  .share-button {
    color: #bb86fc;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background: rgba(187, 134, 252, 0.1);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .buy {
    height: 45px !important;
    width: 162px !important;
  }
  
  .share-button:hover {
    background: rgba(187, 134, 252, 0.2);
  }
  
  @media (max-width: 768px) {
    .settings-page {
      padding: 15px;
    }
  
    .settings-section {
      padding: 15px;
    }
  
    .setting-item {
      padding: 12px 0;
    }
  }

  .install-button {
    background-color: #bb86fc; /* Match the theme color */
    color: white; /* Text color */
    padding: 10px 15px; /* Padding for the button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    text-decoration: none; /* Remove underline */
    display: inline-flex; /* Align items */
    align-items: center; /* Center items vertically */
  }
  
  .install-button:hover {
    background-color: #9a67d9; /* Darker shade on hover */
  }