.search-page {
  padding: 20px;
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.search-container {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.search-input-container {
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  padding: 12px 16px;
  border-radius: 8px;
}

.search-input-container .search-icon {
  color: #888;
  margin-right: 12px;
  font-size: 18px;
}

.search-input-container input {
  flex: 1;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  outline: none;
}

.search-input-container input::placeholder {
  color: #888;
}

.search-results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 16px;
  padding: 16px 0;
}

@media (min-width: 768px) {
  .search-results-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
  }
}

.no-results,
.search-prompt {
  text-align: center;
  color: #888;
  margin-top: 40px;
  font-size: 16px;
}

.search-prompt {
  color: #666;
} 