/* src/MovieDetail.css */

.movie-detail {
  width: 100%;
  max-width: 100%;
}

.container.movie-detail {
  padding: 0;
}

.movie-detail .page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.movie-detail .back-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 10px;
}

.movie-detail .bookmark-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: auto; /* This will push the bookmark button to the right */
}

/* Add a title placeholder to maintain center spacing */
.movie-detail .header-title {
  flex: 1;
  text-align: center;
}

.actor-link {
  color: #a785ff; /* Example color for the link */
  text-decoration: none; /* Remove underline */
  font-weight: 500;
}

.video-container {
  /* width: 100vw; */
  /* margin: 0 calc(-50vw + 50%); */
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  background: #000;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fullscreen-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  z-index: 10;
}

.movie-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.movie-poster {
  max-width: 100%;
  height: auto;
}

.movie-details {
  flex: 1;
}

.movie-details h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.movie-details ul {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .movie-info {
    flex-direction: row;
    align-items: flex-start;
  }

  .movie-poster {
    max-width: 40%;
    /* margin-left: 10px; */
    order: 1; /* Move poster to the left */
  }

  .movie-details {
    flex: 1;
    order: 2;
  }
}

@media (min-width: 769px) {
  .movie-info {
    flex-direction: row;
  }

  .movie-poster {
    max-width: 180px;
    margin-right: 20px;
  }
}



.data-cleaning-section {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.data-cleaning-section input {
  flex-grow: 1;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.data-cleaning-section button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.data-cleaning-section button:hover {
  background-color: #0056b3;
}

.cleaning-response {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-all;
}

@media (max-width: 768px) {
  .data-cleaning-section {
    flex-direction: column;
    align-items: stretch;
  }

  .data-cleaning-section input,
  .data-cleaning-section button {
    width: 100%;
  }
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  color: #070707; /* Light text color for buttons */
}

.movie-title {
  margin: 0;
  text-align: center;
  flex-grow: 1;
  color: white
}

.nav-button {
  padding: 10px 20px;
  background-color: rgb(50, 49, 49);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 0; */
  margin-bottom: 15px;
}



/* Media query for mobile devices */
@media (max-width: 768px) {
  .nav-button {
    padding: 8px 12px;
    font-size: 12px;
  }
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}



/* Update existing styles or add these new styles */

.container.movie-detail {
  padding: 0; /* Remove padding from container when it's a movie detail page */
}

/* Adjust the row with back button and bookmark to stay on top */
.movie-detail .row {
  position: absolute;
  top: 20px; /* Increased from 10px */
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0 20px;
  margin-bottom: 0; /* Reset margin since it's absolutely positioned */
}



/* Add spacing below video container */
.navigation-buttons {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Maintain padding for other content */
.movie-detail .movie-info,
.movie-detail .cast-crew-section {
  padding: 0 10px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .video-container {
    margin-top: 40px; /* Slightly less margin on mobile */
  }
  
  
}


.cast-scroll {
  display: flex;
  overflow-x: auto;
  gap: 12px;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.cast-scroll::-webkit-scrollbar {
  display: none;
}

.cast-card {
  flex: 0 0 auto;
  width: 100px; /* Smaller width for mobile */
  cursor: pointer;
  transition: transform 0.2s;
}

.cast-card:hover {
  transform: scale(1.05);
}

.actor-image {
  width: 100px; /* Match card width */
  height: 150px; /* Maintain aspect ratio */
  border-radius: 8px;
  overflow: hidden;
  background: #1e1e1e;
}

.actor-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.actor-info {
  margin-top: 8px;
  text-align: center;
}

.actor-name {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 4px;
  color: #fff;
}

.actor-character {
  font-size: 11px;
  color: #888;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .cast-card {
    width: 140px;
  }

  .actor-image {
    width: 140px;
    height: 210px;
  }

  .actor-name {
    font-size: 15px;
  }

  .actor-character {
    font-size: 13px;
  }
}

.unmute-button {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.unmute-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}