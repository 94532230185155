/* src/MovieGrid.css */

.movie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  @media (min-width: 768px) {
    .movie-grid {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 20px;
      padding: 20px;
    }
  }
  
  /* Update MovieCard styles if needed */
  .movie-card {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .movie-card:hover {
    transform: scale(1.05);
  }
  
  .movie-card img {
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
  }
  
  .movie-info {
    padding: 8px;
  }
  
  .movie-info h3 {
    margin: 0;
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .movie-info p {
    margin: 4px 0 0;
    color: #BDBDBD;
  }
  
  @media (min-width: 768px) {
    .movie-info {
      padding: 10px;
    }
  
    .movie-info h3 {
      font-size: 1em;
    }
  
    .movie-info p {
      font-size: 0.9em;
    }
  }

.random-movie-container {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  padding: 5px 0;
}

/* Ensure your movie grid has these media queries */
.movie-grid {
  display: grid;
  gap: 20px;
  padding: 20px;
}

@media (max-width: 768px) {
  .movie-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row on mobile */
  }
}

@media (min-width: 769px) {
  .movie-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 items per row on web */
  }
}