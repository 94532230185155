/* src/layout.css */

* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    background-color: #121212; /* Dark background */
    color: #ffffff; /* Light text color */
  }

 

  /* Button styles */
  button {
    background-color: #1e1e1e; /* Dark button background */
    color: #ffffff; /* Light text color for buttons */
    border: 1px solid #ffffff; /* Light border for buttons */
  }




  /* Link styles */
  a {
    color: #bb86fc; /* Light purple color for links */
  }

  a:hover {
    color: #ffffff; /* Change link color on hover */
  }
  
  .container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px 10px 70px 10px;
    overflow-x: hidden;
  }
  
  .movie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }
  
  .movie-detail {
    width: 100%;
    max-width: 100%;
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 1200px;
      padding: 0 20px;
    }
  
    .movie-grid {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 20px;
    }
  }

  .app-header {
    margin: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .app-header h1 {
    margin: 0;
  }

  .header-actions {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .watch-later-link {
    color: #ffffff;
    font-size: 24px;
    display: flex;
    align-items: center;
  }

  .watch-later-link:hover {
    text-decoration: underline;
  }

  .watch-later-header {
    justify-content: center;
    position: relative;
  }
  
  .watch-later-header h1 {
    flex-grow: 1;
    text-align: center;
  }
  
  .back-button {
    position: absolute;
    left: 0;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .watch-later-no-movies-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px); /* Adjust this value based on your header height */
    text-align: center;
  }

  .watch-later-no-movies-message p {
    margin: 10px 0;
  }

  .inline-bookmark-icon {
    vertical-align: middle;
    margin-bottom: 3px; /* Adjust as needed to align with text */
  }