.watch-later-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.page-header h1 {
  flex: 1;
  margin: 0;
  font-size: 24px;
  text-align: center;
}

.back-button {
  position: absolute;
  left: 0;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.watch-later-no-movies-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  text-align: center;
  color: #888;
}

.watch-later-no-movies-message p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .watch-later-page {
    padding: 15px;
  }
} 