/* src/Filters.css */

.filters {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .filters {
    padding-top: 1rem;
  }
}

.filters .select-container {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  touch-action: manipulation;
}

/* Style for react-select components */
.filters .select-container > div {
  width: 100%;
}

.filters .clear-button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.filters .clear-button:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  .filters .select-container {
    flex: 0 0 calc(50% - 5px);
    max-width: calc(50% - 5px);
    margin-bottom: 10px;
  }

  /* Remove any existing min-width on the select containers */
  .filters .select-container {
    min-width: 0;
  }
}

/* Remove or comment out the separate 480px media query if it exists */