/* src/MovieCard.css */

.movie-card {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  border-radius: 6px; /* Slightly smaller border radius */
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.movie-card:hover {
  transform: scale(1.05);
}

.poster-container {
  position: relative;
  width: 100%;
  padding-top: 150%; /* This creates a 2:3 aspect ratio */
  overflow: hidden;
}

.movie-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.movie-info {
  padding: 6px; /* Smaller padding for mobile */
}

.movie-info h3 {
  margin: 0;
  font-size: 0.9em; /* Smaller font size for mobile */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-info p {
  margin: 3px 0 0;
  color: #666;
}

@media (min-width: 768px) {
  .movie-card {
    border-radius: 8px;
  }

  .movie-info {
    padding: 10px;
  }

  .movie-info h3 {
    font-size: 1em;
  }

  .movie-info p {
    margin: 5px 0 0;
    font-size: 0.9em;
  }
}

.remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  z-index: 1;
  padding: 0;
}

.remove-button:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
  transition: all 0.2s ease;
}

.remove-button svg {
  width: 16px;
  height: 16px;
}