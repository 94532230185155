.random-movie-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 24px;
    align-items: center;
    justify-content: center;
  }
  
  .random-movie-button:hover {
    background-color: #45a049;
  }
  
  .play-icon {
    margin-right: 8px;
    font-size: 0.8em;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
  }

  .continue-watching-section h3 {
    margin-left: 20px;
    color: #fff;
  }

  .continue-watching-scroll {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    scrollbar-width: thin;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .continue-watching-scroll .movie-card {
    flex: 0 0 auto;
    width: 180px;
    min-width: 180px;
    margin: 0;
    transform-origin: center;
    transition: transform 0.2s;
  }

  @media (max-width: 768px) {
    .continue-watching-scroll .movie-card {
      width: 140px;
      min-width: 140px;
    }
  }

  .continue-watching-scroll .movie-card:hover {
    transform: scale(1.05);
  }

  .continue-watching-scroll .movie-card img {
    width: 100%;
    height: auto;
    aspect-ratio: 2/3;
    object-fit: cover;
    border-radius: 8px;
  }

.continue-watching-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .header-title h3 {
    margin: 0;
    color: #fff;
  }

  .close-button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.2s, transform 0.2s;
    border-radius: 50%;
  }

  .close-button:hover {
    opacity: 1;
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.1);
  }