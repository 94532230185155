.expandable-text {
    font-family: Arial, sans-serif;
    color: #BDBDBD;
    line-height: 1;
  }
  
  .text-content {
    display: inline;
  }
  
  .toggle-button {
    background: none;
    border: none;
    color: #0066cc;
    cursor: pointer;
    font-size: inherit;
    font-weight: bold;
    padding: 0;
    margin-left: 4px;
    text-decoration: none;
    display: inline-block;
  }
  
  .toggle-button:hover {
    text-decoration: underline;
    color: #004499;
  }
  
  .toggle-button:focus {
    outline: none;
    text-decoration: underline;
  }