.install-page {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  color: #fff;
}

.install-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.install-header h1 {
  flex: 1;
  margin: 0;
  font-size: 24px;
  text-align: center;
}

.back-button {
  position: absolute;
  left: 0;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.install-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjust based on your header height */
  padding: 1rem;
  overflow-y: auto;
}

.install-content h2 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 20px;
  text-align: center;
}

.install-section {
  margin-bottom: 1rem; /* Reduce margin */
}

.install-section:last-child {
  margin-bottom: 0;
}

.install-section h3 {
  margin-bottom: 12px;
  color: #bb86fc;
}

.install-section ol {
  margin: 0;
  padding-left: 24px;
}

.install-section li {
  margin-bottom: 8px;
  line-height: 1.5;
}

.icon {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.desktop-icon {
  font-size: 18px;
}

.store-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #bb86fc;
  color: #000;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0;
  transition: background-color 0.2s;
}

.store-link:hover {
  background-color: #9965f4;
  color: #000;
}

.store-link svg {
  font-size: 24px;
}

@media (max-width: 768px) {
  .install-page {
    padding: 15px;
  }

  .install-content {
    padding: 15px;
  }
}

.website-section {
  margin-bottom: 1rem; /* Reduce margin */
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #333;
}

.website-section p {
  margin: 0 0 10px 0;
  color: #888;
  font-size: 16px;
}

.website-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #bb86fc;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s;
}

.website-link:hover {
  color: #9965f4;
}

.website-link svg {
  font-size: 14px;
}

@media (max-width: 768px) {
  .website-section {
    margin-top: 20px;
    padding-top: 15px;
    opacity: 0.7;
  }

  .website-section p {
    margin: 0 0 5px 0;
    font-size: 14px;
  }

  .website-link {
    gap: 6px;
    font-size: 14px;
  }

  .website-link svg {
    font-size: 12px;
  }
}

.install-gif {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0; /* Remove top margin */
}

.install-guide-gif {
  max-height: 40vh; /* Limit height to 40% of viewport height */
  width: auto;
  object-fit: contain;
}

.show-tutorial-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.show-tutorial-btn:hover {
  background-color: #0056b3;
} 